import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DarkModeProvider } from '@components';
import { Render } from '@layouts';
import { Configuration, PWAInstaller, Translator, LocalAuth } from '@context';
import { adminUrls } from '@root/config';
import '@root/polyfill';
import { HomePage, New, Profile, Credits, Calendar, BookingEdit, MyCalendar } from '@pages';

import {
  ListBulletIcon,
  AdjustmentsHorizontalIcon,
  BanknotesIcon,
  CalendarDaysIcon
} from '@heroicons/react/24/outline';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

const links = [
  { name: 'Visualisation', isTitle: true, isMobile: false },
  // {
  //   name: 'Carte',
  //   pageName: 'map',
  //   icon: PuzzlePieceIcon,
  //   renderPage: Map,
  //   sideComponent: SlideOver,
  //   isMobile: false
  // },
  {
    name: 'Mon calendrier',
    pageName: 'my-calendar',
    icon: CalendarDaysIcon,
    renderPage: MyCalendar,
    isMobile: true
  },
  {
    name: 'Calendrier des salles',
    pageName: 'calendar',
    icon: CalendarDaysIcon,
    renderPage: Calendar,
    isMobile: false
  },
  { name: 'Ressources', isTitle: true, isMobile: false },
  {
    name: 'Réservations',
    pageName: 'booking',
    icon: ListBulletIcon,
    renderPage: HomePage,
    renderEdit: BookingEdit,
    isMobile: true
  },
  {
    name: 'Crédits',
    icon: BanknotesIcon,
    pageName: 'credit',
    renderPage: Credits,
    isMobile: true
  },
  { name: 'Configuration', isTitle: true, isMobile: false },
  {
    name: 'Profil',
    icon: AdjustmentsHorizontalIcon,
    pageName: 'profile',
    renderPage: Profile,
    isMobile: true
  }
];

const router = createBrowserRouter([
  {
    path: '/admin',
    loader: async () => redirect(adminUrls)
  },
  {
    path: '/',
    loader: async () => redirect('/my-calendar')
  },
  {
    path: '/:pageName',
    element: <Render links={links.map((o) => ({ ...o, render: o?.renderPage }))} />
  },
  {
    path: '/edit/:pageName/:id?',
    element: <Render links={links.map((o) => ({ ...o, render: o?.renderEdit }))} />
  },
  {
    path: '/create/booking',
    element: <Render links={links.map((o) => ({ ...o, render: New }))} />
  },
  {
    path: '/forgot-password',
    element: <LocalAuth.ForgotPassword />
  },
  {
    path: '/reset-password',
    element: <LocalAuth.ResetPassword />
  },
  {
    path: '/register',
    element: <LocalAuth.Register />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkModeProvider>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Translator.Provider>
          <PWAInstaller.Provider ignore={['/reset-password']}>
            <Configuration.Provider>
              <LocalAuth.Provider
                ignore={['/reset-password', '/forgot-password', '/register']}
                forgotPassword="/forgot-password"
                forgotPasswordLabel="Mot de passe oublié ?"
                register="/register"
                registerLabel="Pas encore membre ?"
                registerLinkLabel="S'enregistrer"
                hasRememberMe
                hasLabel={false}
                classNameImage="w-72 lg:w-56">
                <RouterProvider router={router} />
              </LocalAuth.Provider>
            </Configuration.Provider>
          </PWAInstaller.Provider>
        </Translator.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  </DarkModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
