import { QueryCache, useQueryClient } from '@tanstack/react-query';
import JWTAuthContext from './JWTAuthContext';
import { decodeJwt } from 'jose';
import { useState } from 'react';

function JWTAuthProvider(props) {
  const {
    children = null,
    param = 'code',
    errorHandlers = {
      403: () => (
        <main className="flex flex-col items-center justify-center w-full px-6 py-24 sm:py-64 lg:px-8">
          <p className="text-base font-semibold leading-8 text-indigo-600">403</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Access Denied
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, you don&apos;t have access rights.
          </p>
        </main>
      )
    }
  } = props;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [renderResult, setRenderResult] = useState(null);

  // Get the current url and match with ignore array
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get(param);
  const tokenToDecode = token || sessionStorage.getItem('token');

  // Store in storage
  if (token !== null && sessionStorage.getItem('token') !== token) {
    sessionStorage.setItem('token', token);
    window.location.reload();
  }

  // Extract content
  let content = null;

  try {
    content = decodeJwt(tokenToDecode);
  } catch (e) {
    console.log('Error falsified token');
  }

  const queryClient = useQueryClient();
  const defautlQueryCache = queryClient.getQueryCache().config;
  queryClient.queryCache = new QueryCache({
    onError: (error) => {
      // override query cache on error attribute, initialized in the main project
      defautlQueryCache.onError(error);
      if (
        Object.keys(errorHandlers)
          .map((key) => parseInt(key))
          .includes(error.response.status)
      ) {
        setIsAuthorized(false);
        setRenderResult(errorHandlers[error.response.status]);
      }
    }
  });

  return (
    <>
      <JWTAuthContext.Provider value={content}>
        {isAuthorized ? children : renderResult}
      </JWTAuthContext.Provider>
    </>
  );
}

export default JWTAuthProvider;
